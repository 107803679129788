import React from "react"
import { Helmet } from 'react-helmet'

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../assets/scss/styles.css"
import "../../assets/scss/tour-list.css"

import Header from '../../components/header'
import Footer from '../../components/footer'

import article from '../../data/articles/covid-france-assouplissement'

const Article = ({ location }) => {
  return (
    <main>
			<Helmet>
				<meta charSet="utf-8" />
        <title>CoTraveling - Article {article.name}</title>
				<meta name="description" content="Des séjours entre indépendants pour travailler, se détendre et échanger. Dubaï, Phuket, Tokyo et plus encore de destinations pour vos séjours de CoTraveling." />
			</Helmet>
      <div class="container">
        <Header pathname={location.pathname} />
        <div class="image_bg--single mblog_bg-single" style={{minHeight: '60%', backgroundImage: `url(${article.image})`}}></div>
        <div class="page_content single-page blog-single">
          <div class="content-head">
            <div class="wrap">
              <div class="wrap_float">
                <div class="main">
                  <div class="title">
                    {article.name}
                  </div>
                  <div class="date">
                    {article.date} | {article.category}
                  </div>
                  <div class="description">
                    <p>
                      {article.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-body">
            <div class="wrap">
              <div class="wrap_float">
                <div class="page--content">
                  {article.content}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer pathname={location.pathname} />
      </div>
    </main>
  )
}

export default Article
